import { ref, computed } from "vue";
import { useRouter } from 'vue-router'
import { getItem, setItem } from "@/services/items";
import store from "@/store";

export const useMenuEdit = ({ id = null }) => {
    const router = useRouter();

    const orgs = computed(() => store.state.user.profile.orgs);
    const privilege = computed(() => store.state.user.profile.privilege);
    const menu = ref({
        name: "",
        siteId: 0,
        menuId: 0,
        env: "dev",
        org: orgs.value[0],
    });

    const getMenu = async () => {
        store.dispatch("setLoading", true);
        try {
            menu.value = await getItem({ table: 'menus', id });
        } catch (error) {
            store.dispatch("setError", error);
            console.error({ error })
        }
        store.dispatch("setLoading", false);
    };
    getMenu();

    const updateMenu = async () => {
        store.dispatch("setLoading", true);
        try {
            await setItem({ table: 'menus', menu: menu.value });
            store.dispatch("setSuccess", 'Menu updated');
            router.push('/');
        } catch (error) {
            store.dispatch("setError", error);
            console.error({ error })
        }
        store.dispatch("setLoading", false);
    };

    return {
        menu: computed(() => menu.value),
        privilege: computed(() => privilege.value),
        updateMenu,
    }
}